import { isBrowser } from '@utils/isBrowser';
import dynamic from 'next/dynamic';

const LazyPortal = dynamic(() => import('./Portal'), { ssr: false });

export const HideHubspot = () => {
  if (!isBrowser()) return null;

  return (
    <LazyPortal>
      <style type="text/css">
        {'#hubspot-messages-iframe-container { display: none !important; }'}
      </style>
    </LazyPortal>
  );
};
