/* eslint-disable react/no-unescaped-entities */
import {
  Alert,
  AvantArteLogo,
  Button,
  Icon,
  InputField,
  PulsingDot,
  TextField,
} from '@design-system';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import type { GetStaticProps } from 'next';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { csrClient } from '../../apollo/api/avantArteClient';
import { DialogWrapper } from '../../components/ArtistV2/Dialogs/DialogWrapper';
import {
  LazyCloudflareTurnstile,
  useResetTurnstile,
} from '../../components/Captcha/LazyCloudflareTurnstile';
import { HideHubspot } from '../../components/HideHubspot';
import { Image } from '../../components/Image';
import { SimpleLayout } from '../../components/Layout/SimpleLayout';
import { isValidEmail } from '../../helpers/email';
import { useDialogContext } from '../../hooks/useDialogContext';
import classes from '../../styles/artebio.module.css';
import {
  GeneratedSlackChannel,
  useSlackMessageMutation,
} from '../../types/generated';

function fromatNumber(value: number) {
  return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
}

const slides = [
  [
    {
      id: 'bb597eb8-7078-4443-b40a-0863cff79386',
      title:
        'Join the newsletter for exclusive access to upcoming events and projects',
      overline: null,
      date: null,
      initialCount: 1078,
      metrics: 'subscribed',
      variant: 'subscribe',
      pill: null,
      image: {
        filename: '/images/artebio/mickalene-thomas.jpg',
        alt: '',
      },
      imageAlt: null,
      icon: null,
      isActive: false,
      hasCta: false,
    },
  ],
  [
    {
      id: '2738e466-7559-40ef-b8df-f9b21e204ed8',
      title: 'Print edition',
      overline: 'from €900',
      date: null,
      initialCount: 668,
      metrics: 'want to buy',
      variant: 'product',
      pill: 'Available',
      image: {
        filename: '/images/artebio/ed-ruscha-actual-size.jpg',
        alt: '',
      },
      imageAlt: {
        filename: '/images/artebio/mickalene-thomas-maya.jpg',
        alt: '',
      },
      icon: null,
      isActive: true,
      hasCta: false,
    },
  ],
  [
    {
      id: 'ab632ae2-e1da-4a5e-bfd1-b3c23ad833f4',
      title: 'Man Smashes Ai Weiwei’s Porcelain Sculpture at Italian Museum',
      overline: null,
      date: null,
      initialCount: 302,
      metrics: 'read',
      variant: 'article',
      pill: null,
      image: {
        filename: '/images/artebio/ai-weiwei-article.jpg',
        alt: '',
      },
      imageAlt: null,
      icon: {
        filename: '/images/artebio/times-logo.jpg',
        alt: '',
      },
      isActive: false,
      hasCta: false,
    },
    {
      id: '798f6449-f64d-4dca-8228-c27b2aabf97e',
      title: '',
      overline: null,
      date: null,
      initialCount: 721,
      metrics: 'watched',
      variant: 'video',
      pill: null,
      image: {
        filename: '/images/artebio/george-condo-video.jpg',
        alt: '',
      },
      imageAlt: null,
      icon: null,
      isActive: false,
      hasCta: false,
    },
  ],
  [
    {
      id: '26825fa3-ee02-4fa8-9d46-b28ba3e98654',
      title: '',
      overline: null,
      date: null,
      initialCount: 2142,
      metrics: 'watched',
      variant: 'video',
      pill: null,
      image: {
        filename: '/images/artebio/elizabeth-peyton-video.jpg',
        alt: '',
      },
      imageAlt: null,
      icon: null,
      isActive: false,
      hasCta: false,
    },
    {
      id: '272511e2-353a-4e4c-bae3-e5202537a761',
      title:
        'Jenny Holzer on How Artists Can Use Outrage to Expose the Hypocrisies of Our Time',
      overline: null,
      date: null,
      initialCount: 480,
      metrics: 'read',
      variant: 'article',
      pill: null,
      image: {
        filename: '/images/artebio/jenny-holzer-article.jpg',
        alt: '',
      },
      imageAlt: null,
      icon: {
        filename: '/images/artebio/artnet-logo.jpg',
        alt: '',
      },
      isActive: false,
      hasCta: false,
    },
    {
      id: 'bb597eb8-7078-4443-b40a-0863cff79386',
      title:
        'Join the newsletter for exclusive access to upcoming events and projects',
      overline: null,
      date: null,
      initialCount: 1312,
      metrics: 'subscribed',
      variant: 'subscribe',
      pill: null,
      image: {
        filename: '/images/artebio/jenna-gribbon.jpg',
        alt: '',
      },
      imageAlt: {
        filename: '/images/artebio/grayson-perry.jpg',
        alt: '',
      },
      icon: null,
      isActive: false,
      hasCta: false,
    },
  ],
  [
    {
      id: '2738e466-7559-40ef-b8df-f9b21e204ed8',
      title: 'Sculpture edition',
      overline: 'Acrylic on Han dynasty vase',
      date: null,
      initialCount: 1047,
      metrics: 'want to buy',
      variant: 'product',
      pill: 'Available',
      image: {
        filename: '/images/artebio/ai-weiwei-vase.jpg',
        alt: '',
      },
      imageAlt: null,
      icon: null,
      isActive: true,
      hasCta: false,
    },
    {
      id: 'a7455018-7772-4dbe-aa20-2c1d97649d9c',
      title: 'Official website',
      overline: 'mickalene.herokuapp.com',
      date: null,
      initialCount: 908,
      metrics: 'visited',
      variant: 'link',
      pill: null,
      image: {
        filename: '/images/artebio/mickalene-thomas-logo.jpg',
        alt: '',
      },
      imageAlt: null,
      icon: null,
      isActive: false,
      hasCta: false,
    },
  ],
  [
    {
      id: '0a41372b-e2a5-4cf8-afe3-2b8de6983e8d',
      title: 'NOW / THEN',
      overline: 'LACMA, Los Angeles',
      date: 'Until October 06, 2024',
      initialCount: 637,
      metrics: 'want to visit',
      variant: 'event',
      pill: null,
      image: {
        filename: '/images/artebio/ed-ruscha-exhibition-01.jpg',
        alt: '',
      },
      imageAlt: {
        filename: '/images/artebio/ed-ruscha-exhibition-02.jpg',
        alt: '',
      },
      icon: null,
      isActive: false,
      hasCta: true,
    },
  ],
];

type PreviewCardVariant =
  | 'link'
  | 'product'
  | 'article'
  | 'event'
  | 'video'
  | 'subscribe';

const PreviewCard = ({
  count,
  date,
  handleClick,
  hasCta,
  icon,
  id,
  image,
  imageAlt,
  isActive,
  metrics,
  overline,
  pill,
  title,
  variant,
}: {
  id: string;
  variant: PreviewCardVariant;
  metrics: string;
  count: number;
  image: {
    filename: string;
    alt: string;
  };
  imageAlt: {
    filename: string;
    alt: string;
  } | null;
  icon: {
    filename: string;
    alt: string;
  } | null;
  title: string;
  overline: string | null;
  date: string | null;
  pill: string | null;
  isActive: boolean;
  hasCta: boolean;
  handleClick: (id: string) => void;
}) => {
  const mapVariantIcon = {
    link: 'M212.31-140Q182-140 161-161q-21-21-21-51.31v-535.38Q140-778 161-799q21-21 51.31-21h222.3q12.77 0 21.39 8.62 8.61 8.61 8.61 21.38T456-768.62q-8.62 8.62-21.39 8.62h-222.3q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v535.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85h535.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46v-222.3q0-12.77 8.62-21.39 8.61-8.61 21.38-8.61t21.38 8.61q8.62 8.62 8.62 21.39v222.3Q820-182 799-161q-21 21-51.31 21H212.31ZM760-717.85 409.85-367.69q-8.31 8.3-20.89 8.5-12.57.19-21.27-8.5-8.69-8.7-8.69-21.08 0-12.38 8.69-21.08L717.85-760H590q-12.77 0-21.38-8.62Q560-777.23 560-790t8.62-21.38Q577.23-820 590-820h193.84q15.47 0 25.81 10.35Q820-799.31 820-783.84V-590q0 12.77-8.62 21.38Q802.77-560 790-560t-21.38-8.62Q760-577.23 760-590v-127.85Z',
    product:
      'M212.31-100q-29.92 0-51.12-21.19Q140-142.39 140-172.31v-455.38q0-29.92 21.19-51.12Q182.39-700 212.31-700H300q0-74.92 52.54-127.46Q405.08-880 480-880q74.92 0 127.46 52.54Q660-774.92 660-700h87.69q29.92 0 51.12 21.19Q820-657.61 820-627.69v455.38q0 29.92-21.19 51.12Q777.61-100 747.69-100H212.31Zm0-60h535.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46v-455.38q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H212.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v455.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85ZM360-700h240q0-50-35-85t-85-35q-50 0-85 35t-35 85ZM200-160v-480 480Zm279.38-260q67.85 0 121.89-45.92 54.04-45.93 53.42-104.23 0-12.39-8.31-21.12-8.3-8.73-21.07-8.73-10.54 0-18.85 7.27-8.31 7.27-11.77 20.27-8.69 40.69-41.46 66.58Q520.46-480 479.38-480q-41.07 0-74.15-25.88-33.08-25.89-41.15-66.58-3.46-13.62-11.16-20.58-7.69-6.96-18.23-6.96-12.77 0-21.38 8.73-8.62 8.73-8.62 21.12 0 58.3 53.43 104.23Q411.54-420 479.38-420Z',
    article:
      'M480.09-336.92q67.99 0 115.49-47.59t47.5-115.58q0-67.99-47.59-115.49t-115.58-47.5q-67.99 0-115.49 47.59t-47.5 115.58q0 67.99 47.59 115.49t115.58 47.5ZM480-392q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 172q-126.31 0-231.04-67.39-104.73-67.38-167.19-177.3-5-8.62-7.31-17.37-2.3-8.75-2.3-17.96t2.3-17.94q2.31-8.73 7.31-17.35 62.46-109.92 167.19-177.3Q353.69-780 480-780q126.31 0 231.04 67.39 104.73 67.38 167.19 177.3 5 8.62 7.31 17.37 2.3 8.75 2.3 17.96t-2.3 17.94q-2.31 8.73-7.31 17.35-62.46 109.92-167.19 177.3Q606.31-220 480-220Zm0-280Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z',
    event:
      'M172.31-180Q142-180 121-201q-21-21-21-51.31v-455.38Q100-738 121-759q21-21 51.31-21h615.38Q818-780 839-759q21 21 21 51.31v455.38Q860-222 839-201q-21 21-51.31 21H172.31ZM800-662.31 499.46-469.92q-4.61 2.61-9.54 4.11-4.92 1.5-9.92 1.5t-9.92-1.5q-4.93-1.5-9.54-4.11L160-662.31v410q0 5.39 3.46 8.85t8.85 3.46h615.38q5.39 0 8.85-3.46t3.46-8.85v-410ZM480-520l313.85-200h-627.7L480-520ZM160-662.31v9.23-45.73 1.19V-720v22.38-1.27V-653.08v-9.23V-240v-422.31Z',
    video:
      'M480.09-336.92q67.99 0 115.49-47.59t47.5-115.58q0-67.99-47.59-115.49t-115.58-47.5q-67.99 0-115.49 47.59t-47.5 115.58q0 67.99 47.59 115.49t115.58 47.5ZM480-392q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 172q-126.31 0-231.04-67.39-104.73-67.38-167.19-177.3-5-8.62-7.31-17.37-2.3-8.75-2.3-17.96t2.3-17.94q2.31-8.73 7.31-17.35 62.46-109.92 167.19-177.3Q353.69-780 480-780q126.31 0 231.04 67.39 104.73 67.38 167.19 177.3 5 8.62 7.31 17.37 2.3 8.75 2.3 17.96t-2.3 17.94q-2.31 8.73-7.31 17.35-62.46 109.92-167.19 177.3Q606.31-220 480-220Zm0-280Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z',
    subscribe:
      'M172.31-180Q142-180 121-201q-21-21-21-51.31v-455.38Q100-738 121-759q21-21 51.31-21h615.38Q818-780 839-759q21 21 21 51.31v455.38Q860-222 839-201q-21 21-51.31 21H172.31ZM800-662.31 499.46-469.92q-4.61 2.61-9.54 4.11-4.92 1.5-9.92 1.5t-9.92-1.5q-4.93-1.5-9.54-4.11L160-662.31v410q0 5.39 3.46 8.85t8.85 3.46h615.38q5.39 0 8.85-3.46t3.46-8.85v-410ZM480-520l313.85-200h-627.7L480-520ZM160-662.31v9.23-45.73 1.19V-720v22.38-1.27V-653.08v-9.23V-240v-422.31Z',
  } as const;

  // eslint-disable-next-line react/no-unstable-nested-components
  const PreviewCardDetails = ({
    theme = 'light',
  }: {
    theme?: 'light' | 'dark';
  }) => (
    <div className="flex w-full gap-3">
      {image && variant === 'link' ? (
        <div className="relative flex h-12 w-12 shrink-0 overflow-hidden rounded-md bg-black/80">
          <Image
            alt={image.alt || ''}
            hoverEffect
            src={image.filename}
            width={48}
          />

          {imageAlt ? (
            <Image
              alt={imageAlt.alt || ''}
              className={classNames(classes.ImageAlt)}
              eagerLoading
              hoverEffect
              src={imageAlt.filename}
              width={48}
            />
          ) : null}
        </div>
      ) : null}

      <div className="flex w-full flex-1 flex-col py-1">
        <p
          className={classNames(
            { 'text-white': theme === 'dark' },
            'text-balance font-bold',
          )}
        >
          {title}
        </p>

        {overline ? (
          <p
            className={classNames(
              theme === 'dark' ? 'text-white/60' : 'text-neutral-4',
              'text-balance',
            )}
          >
            {overline}
          </p>
        ) : null}

        {date ? (
          <p
            className={classNames(
              theme === 'dark' ? 'text-white/60' : 'text-neutral-4',
              'text-balance',
            )}
          >
            {date}
          </p>
        ) : null}
      </div>
    </div>
  );

  return (
    <div className="flex w-full flex-col gap-4">
      <button
        className="group flex w-full cursor-pointer flex-col overflow-hidden rounded-lg bg-white text-left text-black transition duration-300 ease-out active:scale-95"
        onClick={() => handleClick(id)}
        type="button"
      >
        {image && variant !== 'link' && variant !== 'subscribe' ? (
          <div
            className={classNames(
              variant === 'product' ? 'aspect-[1/1]' : 'aspect-[3/2]',
              'relative flex w-full flex-col items-center justify-center overflow-hidden bg-black/80',
            )}
          >
            <Image
              alt={image.alt || ''}
              eagerLoading
              hoverEffect
              src={image.filename}
              width={384}
            />
            {imageAlt ? (
              <div className={classNames(classes.ImageAlt)}>
                <Image
                  alt={imageAlt.alt || ''}
                  eagerLoading
                  hoverEffect
                  src={imageAlt.filename}
                  width={384}
                />
              </div>
            ) : null}

            {variant === 'article' ? (
              <>
                <div className="absolute inset-0 bg-gradient-to-t from-black/30 transition duration-300 ease-out group-hover:opacity-0" />
                <div className="absolute bottom-0 left-0 right-0 p-4">
                  <PreviewCardDetails theme="dark" />
                </div>
              </>
            ) : null}

            {icon ? (
              <div className="absolute left-4 top-4 h-12 w-12 overflow-hidden rounded-sm bg-white">
                <Image
                  alt={icon.alt || ''}
                  eagerLoading
                  hoverEffect
                  src={icon.filename}
                  width={48}
                />
              </div>
            ) : null}

            {pill ? (
              <span className="absolute right-4 top-4 flex items-center justify-center gap-1 rounded-full bg-white p-3">
                {isActive ? <PulsingDot /> : null}
                <span>{pill}</span>
              </span>
            ) : null}

            {variant === 'video' ? (
              <div className="absolute rounded-full bg-white p-2">
                <Icon className="text-black" name="ic_play" size="lg" />
              </div>
            ) : null}
          </div>
        ) : null}

        {variant !== 'article' &&
        variant !== 'video' &&
        variant !== 'subscribe' ? (
          <div className="flex w-full flex-col gap-4 p-4">
            <PreviewCardDetails />

            {hasCta ? (
              <div className="flex items-center justify-center rounded-md bg-black px-6 py-4 text-white">
                <span className="px-1">Get updates</span>
                <svg
                  className={classNames(classes.IconBell)}
                  fill="currentColor"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                >
                  <path d="M210-204.62q-12.75 0-21.37-8.62-8.63-8.63-8.63-21.39 0-12.75 8.63-21.37 8.62-8.61 21.37-8.61h42.31v-298.47q0-80.69 49.81-142.69 49.8-62 127.88-79.31V-810q0-20.83 14.57-35.42Q459.14-860 479.95-860q20.82 0 35.43 14.58Q530-830.83 530-810v24.92q78.08 17.31 127.88 79.31 49.81 62 49.81 142.69v298.47H750q12.75 0 21.37 8.62 8.63 8.63 8.63 21.39 0 12.75-8.63 21.37-8.62 8.61-21.37 8.61H210Zm270-293.07Zm-.07 405.38q-29.85 0-51.04-21.24-21.2-21.24-21.2-51.07h144.62q0 29.93-21.26 51.12-21.26 21.19-51.12 21.19Zm-167.62-172.3h335.38v-298.47q0-69.46-49.11-118.57-49.12-49.12-118.58-49.12-69.46 0-118.58 49.12-49.11 49.11-49.11 118.57v298.47Z" />
                </svg>
              </div>
            ) : null}
          </div>
        ) : null}

        {variant === 'subscribe' ? (
          <div className="flex flex-col items-center justify-center gap-4 p-4">
            {image.filename ? (
              <div className="relative flex h-18 w-18 overflow-hidden rounded-sm bg-black/80">
                <Image
                  alt={image.alt || ''}
                  eagerLoading
                  hoverEffect
                  src={image.filename}
                  width={96}
                />

                {imageAlt ? (
                  <div className={classNames(classes.ImageAlt)}>
                    <Image
                      alt={imageAlt.alt || ''}
                      eagerLoading
                      hoverEffect
                      src={imageAlt.filename}
                      width={96}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}

            <p className="w-full text-balance text-center">{title}</p>

            <div className="flex w-full gap-3">
              <div className="flex-1 rounded-sm p-3 text-neutral-4 ring-1 ring-divider/20">
                Email
              </div>
              <div className="flex h-11 w-11 shrink-0 items-center justify-center rounded-full bg-black text-white">
                <svg
                  className={classNames(classes.IconArrowRight)}
                  fill="currentColor"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                >
                  <path d="M665.08-450H210q-12.77 0-21.38-8.62Q180-467.23 180-480t8.62-21.38Q197.23-510 210-510h455.08L458.31-716.77q-8.92-8.92-8.81-20.88.12-11.96 9.42-21.27 9.31-8.69 21.08-9 11.77-.31 21.08 9l253.61 253.61q5.62 5.62 7.92 11.85 2.31 6.23 2.31 13.46t-2.31 13.46q-2.3 6.23-7.92 11.85L501.08-201.08q-8.31 8.31-20.58 8.5-12.27.19-21.58-8.5-9.3-9.31-9.3-21.38 0-12.08 9.3-21.39L665.08-450Z" />
                </svg>
              </div>
            </div>
          </div>
        ) : null}
      </button>

      <div className="flex w-full items-center justify-center gap-2 text-center text-white/60">
        <svg
          fill="currentColor"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
        >
          <path d={mapVariantIcon[variant]} />
        </svg>
        <p className="text-balance">
          {fromatNumber(count)} {metrics}
        </p>
      </div>
    </div>
  );
};

const ContactDialog = () => {
  const form = useForm<{
    name: string;
    email: string;
    instagram: string;
    twitter: string;
    message: string;
  }>({
    criteriaMode: 'firstError',
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      name: '',
      email: '',
      instagram: '',
      twitter: '',
      message: '',
    },
  });

  const [showCaptcha, setShowCaptcha] = useState(false);

  const [captcha, setCaptcha] = useState<string>();
  const { turnstileRef, turnstileReset } = useResetTurnstile();
  const [slackMessage, slackMessageState] = useSlackMessageMutation({
    client: csrClient, // needed since arte.bio is not part of Apollo context tree
    errorPolicy: 'all',
  });

  const { errors, isLoading, isSubmitting } = form.formState;

  const required = {
    message: 'This is a required field',
    value: true,
  };

  const onSubmit = async () => {
    await slackMessage({
      variables: {
        captcha: captcha as string,
        channel: GeneratedSlackChannel.Artebio,
        msg: `${form.getValues().name}: "${form.getValues().message}"
        
Email: ${form.getValues().email}
Instagram: ${form.getValues().instagram}
Twitter: ${form.getValues().twitter || '–'}`,
      },
    });

    turnstileReset();
  };

  return (
    <div className="w-full px-6 pb-18">
      <div className="mx-auto flex w-full max-w-sm flex-col items-center justify-center gap-4 py-4">
        <div className="flex w-full flex-col">
          <p className="w-full text-balance text-center font-bold">
            Get in touch
          </p>
          <p className="w-full text-balance text-center">
            If you're interested in using arte.bio, get in touch with the team
            using the form below. We'll get back to you as soon as possible.
          </p>
        </div>

        <AnimatePresence>
          {slackMessageState.data ? (
            <motion.div
              animate={{ opacity: 1 }}
              className="w-full"
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
            >
              <Alert status="success">Thank you for your interest!</Alert>
            </motion.div>
          ) : (
            <motion.form
              animate={{ opacity: 1 }}
              className="flex w-full flex-col gap-6"
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              onSubmit={(event) => void form.handleSubmit(onSubmit)(event)}
            >
              {slackMessageState.error ? (
                <Alert status="error">
                  Something went wrong, please try again
                </Alert>
              ) : null}

              <div className="flex w-full flex-col gap-4">
                <InputField
                  id="name"
                  label="Name"
                  placeholder="Charlie Brown"
                  type="text"
                  {...form.register('name', {
                    required,
                  })}
                  disabled={isLoading || isSubmitting}
                  error={errors.name?.message}
                  onFocus={() => {
                    setShowCaptcha(true);
                  }}
                />

                <InputField
                  id="email"
                  label="Email"
                  placeholder="contact@mail.com"
                  type="email"
                  {...form.register('email', {
                    required,
                    validate: (value) => {
                      return (
                        isValidEmail(value) ||
                        'This must be a valid email address'
                      );
                    },
                  })}
                  disabled={isLoading || isSubmitting}
                  error={errors.email?.message}
                />

                <div className="flex w-full flex-col gap-2">
                  <p className="text-xs">Socials</p>
                  <InputField
                    id="instagram"
                    placeholder="https://instagram.com/..."
                    prefix={<Icon name="ic_instagram" />}
                    type="url"
                    {...form.register('instagram', {
                      required,
                      validate: (value) => {
                        const instagramRegex =
                          /^https?:\/\/(www\.)?(instagram\.com|instagr\.am)\/[a-zA-Z0-9_.]+(\/)?$/;

                        return (
                          instagramRegex.test(value) ||
                          'This must be a valid Instagram URL'
                        );
                      },
                    })}
                    disabled={isLoading || isSubmitting}
                    error={errors.instagram?.message}
                  />

                  <InputField
                    id="twitter"
                    placeholder="https://x.com/..."
                    prefix={<Icon name="ic_twitter" />}
                    suffix={<span className="text-xs">(optional)</span>}
                    type="url"
                    {...form.register('twitter', {
                      validate: (value) => {
                        const twitterRegex =
                          /^https?:\/\/(www\.)?(twitter|x)\.com\/[a-zA-Z0-9_]+(\/)?$/;

                        return (
                          !value ||
                          twitterRegex.test(value) ||
                          'This must be a valid Twitter (or X) URL'
                        );
                      },
                    })}
                    disabled={isLoading || isSubmitting}
                    error={errors.twitter?.message}
                  />
                </div>

                <TextField
                  id="message"
                  label="Message"
                  placeholder=""
                  rows={6}
                  {...form.register('message', { required })}
                  disabled={isLoading || isSubmitting}
                  error={errors.message?.message}
                />
              </div>

              <div className="flex w-full flex-col">
                <Button
                  disabled={isLoading || isSubmitting || !captcha}
                  label="Submit"
                  size="lg"
                  type="submit"
                  variant="primary"
                />

                <div className="pt-3">
                  {showCaptcha && (
                    <LazyCloudflareTurnstile
                      actionName="artebio"
                      onError={() => setCaptcha(undefined)}
                      onExpire={() => setCaptcha(undefined)}
                      onSuccess={setCaptcha}
                      turnstileRef={turnstileRef}
                    />
                  )}
                </div>
              </div>
            </motion.form>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

type CountsType = { [key: string]: number };

const ArteBio = () => {
  const { hideDialog, showDialog } = useDialogContext();

  const [counts, setCounts] = useState<CountsType>(() => {
    const initialCounts = slides.flat().reduce<CountsType>((acc, item) => {
      // eslint-disable-next-line no-param-reassign
      acc[item.id] = item.initialCount;
      return acc;
    }, {});
    return initialCounts;
  });

  const handleIncrement = (id: string) => {
    setCounts((prevCounts) => ({
      ...prevCounts,
      [id]: prevCounts[id] + 1,
    }));
  };

  const handleContact = () => {
    return showDialog(
      <DialogWrapper onClose={hideDialog} theme="light-grey">
        <ContactDialog />
      </DialogWrapper>,
    );
  };

  return (
    <SimpleLayout
      canonicalUrl="https://arte.bio/"
      description="Create a bespoke hub to spotlight exhibitions, events, collaborations and more. Along the way, you’ll build a database of fans and collectors – all owned by you."
      ogImageUrl="https://arte.bio/images/artebio/og-image.jpg"
      theme="dark-grey"
      title="arte.bio – link management, designed for artists"
    >
      <main className="justify-space-between flex min-h-screen flex-col text-white">
        <div className="pointer-events-none relative z-20 flex w-full flex-col items-center justify-center gap-12 px-6 py-12 sm:items-start sm:justify-start sm:px-12">
          <AvantArteLogo className="max-sm:hidden" variant="icon" />

          <div className="flex w-full flex-col gap-6 sm:gap-12">
            <h1 className="pointer-events-auto w-full max-w-3xl text-balance text-center text-[3rem] font-bold leading-[0.9] tracking-tight sm:text-left sm:text-[5rem] md:text-[7rem]">
              Connect with your audience
            </h1>
            <h2 className="pointer-events-auto w-full max-w-3xl text-balance text-center text-md leading-snug sm:text-left">
              arte.bio is a link management tool designed for artists. Create a
              bespoke hub to spotlight exhibitions, events, collaborations and
              more. Along the way, you’ll build a database of fans and
              collectors – all owned by you.
            </h2>
          </div>

          <div className="z-50 sm:absolute sm:right-12 sm:translate-y-px">
            <Button
              label="Get in touch"
              onClick={handleContact}
              type="button"
              variant="primary"
            />
          </div>
        </div>

        <div className="z-0 -mt-60 flex w-full flex-1 items-center justify-start overflow-hidden [maskImage:linear-gradient(to_bottom,transparent_0%,_black_75%)] sm:absolute sm:inset-0 sm:mt-0 md:[maskImage:linear-gradient(140deg,transparent_0%,_black_75%)] lg:[maskImage:linear-gradient(140deg,transparent_0%,_black_50%)]">
          <div className="inline-flex w-full flex-nowrap">
            <div className={classNames(classes.AutoScroll, classes.Row)}>
              {slides.map((cards, index) => {
                return (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    className="flex w-[320px] items-center justify-center md:w-[360px]"
                  >
                    <div className="flex w-full flex-col gap-9">
                      {cards.map((card) => {
                        return (
                          <PreviewCard
                            key={`${card.id}-1`}
                            count={counts[card.id]}
                            date={card.date}
                            handleClick={handleIncrement}
                            hasCta={card.hasCta}
                            icon={card.icon}
                            id={card.id}
                            image={card.image}
                            imageAlt={card.imageAlt}
                            isActive={card.isActive}
                            metrics={card.metrics}
                            overline={card.overline}
                            pill={card.pill}
                            title={card.title}
                            variant={card.variant as PreviewCardVariant}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={classNames(classes.AutoScroll, classes.Row)}>
              {slides.map((cards, index) => {
                return (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    className="flex w-[320px] items-center justify-center md:w-[360px]"
                  >
                    <div className="flex w-full flex-col gap-9">
                      {cards.map((card) => {
                        return (
                          <PreviewCard
                            key={`${card.id}-2`}
                            count={counts[card.id]}
                            date={card.date}
                            handleClick={handleIncrement}
                            hasCta={card.hasCta}
                            icon={card.icon}
                            id={card.id}
                            image={card.image}
                            imageAlt={card.imageAlt}
                            isActive={card.isActive}
                            metrics={card.metrics}
                            overline={card.overline}
                            pill={card.pill}
                            title={card.title}
                            variant={card.variant as PreviewCardVariant}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="pointer-events-none z-10 flex w-full flex-col items-center justify-center gap-3 px-6 py-12 sm:fixed sm:bottom-0 sm:left-0 sm:right-0 sm:items-start sm:justify-start sm:px-12">
          <p className="text-xs text-white/60">
            <a
              className="hyperlink pointer-events-auto"
              href="https://avantarte.com/"
              rel="noreferrer"
              target="_blank"
            >
              Powered by Avant Arte
            </a>
          </p>

          <AvantArteLogo className="text-white/40 sm:hidden" variant="icon" />
        </div>
      </main>

      <HideHubspot />
    </SimpleLayout>
  );
};

export default ArteBio;

export const getStaticProps: GetStaticProps = ({
  locale,
  locales,
  preview = false,
}) => {
  return {
    props: {
      isArteBioDomain: true,
      preview,
      locale,
      locales,
    },
  };
};
