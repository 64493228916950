import { Button } from '@design-system';
import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { LayoutTheme } from '../../Layout/types';

interface Props {
  header?: string;
  children: ReactNode;
  compact?: boolean;
  theme?: LayoutTheme;
  onClose?: () => void;
}

export const DialogWrapper = ({
  children,
  compact = true,
  header,
  onClose,
  theme = 'light',
}: Props) => {
  return (
    <div
      className={classNames(
        'relative mx-auto flex max-h-[calc(100%-12vmin)] w-full flex-col overflow-y-scroll rounded-t-lg bg-white outline-0 no-scrollbar md:rounded-lg',
        compact ? 'md:max-w-md' : 'max-w-5xl',
        `theme-${theme}`,
      )}
    >
      <div className="sticky top-0 z-50 flex w-full items-center justify-between bg-white px-6 py-4">
        <div className="h-0 w-9" />
        {header ? (
          <p className="bold w-full px-3 text-center font-bold first-letter:uppercase">
            {header}
          </p>
        ) : null}

        <Button
          hideLabel
          icon="ic_close"
          label="Close"
          onClick={onClose}
          size="sm"
          variant="transparent"
        />
      </div>

      <div className="mx-auto w-full max-w-md md:max-w-none">{children}</div>
    </div>
  );
};
